"use strict";
import Swiper, { Navigation, Pagination } from "swiper";
$(document).ready(function() {
  var swiper = new Swiper(".temoignageSwiper", {
    modules: [Navigation, Pagination],
    autoHeight: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    breakpoints: {
      1024: {
        slidesPerView: 2,
        spaceBetween: 30
      },
      1200: {
        slidesPerView: 3
      }
    }
  });
  var swiper = new Swiper(".sliderhomeSwiper", {
    modules: [Pagination],
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    breakpoints: {
      1024: {
        slidesPerView: 4,
        spaceBetween: 30
      },
      767: {
        slidesPerView: 2,
        spaceBetween: 30
      }
    }
  });
});
$(".step-edit").on("click", function() {
  $(this).parent().siblings(".step__content").show();
});
$(document).on("click", ".product-accessories.pm-csoc .thumbnail-container.with-button", function(e) {
  e.preventDefault();
  e.stopPropagation();
  let optionIdSelect = $(this).parent().attr("data-id-product");
  if (!$(this).hasClass("selected")) {
    $(this).addClass("selected");
    $(this).siblings(".button_display").find("button.add-to-cart").click();
  } else {
    $(this).removeClass("selected");
    $(".remove-from-cart[data-id-product=" + optionIdSelect + "]")[0].click();
  }
});
$(".cart__item").each(function() {
  let getDataProduct = $(this).data("id-product");
  let getDataProductAttribute = $(this).data("id-product-attribute");
  $("#option-" + getDataProduct + "-" + getDataProductAttribute).children().addClass("selected");
});
$(".read-more").on("click", function() {
  $(this).parents("#ohtexthome").children(".more-text").slideToggle();
});
