"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import Quantity from "@constants/useQuantityInput-data";
import { qtyInput as quantityInputMap } from "@constants/selectors-map";
import debounce from "@helpers/debounce";
import useAlert from "./useAlert";
import useToast from "./useToast";
const ENTER_KEY = "Enter";
const ESCAPE_KEY = "Escape";
const ARROW_UP_KEY = "ArrowUp";
const ARROW_DOWN_KEY = "ArrowDown";
const useQuantityInput = (selector = quantityInputMap.default, delay = Quantity.delay) => {
  const qtyInputNodeList = document.querySelectorAll(selector);
  qtyInputNodeList.forEach((qtyInputWrapper) => {
    const qtyInput = qtyInputWrapper.querySelector("input");
    if (qtyInput) {
      const incrementButton = qtyInputWrapper.querySelector(quantityInputMap.increment);
      const decrementButton = qtyInputWrapper.querySelector(quantityInputMap.decrement);
      if (incrementButton && decrementButton) {
        const qtyInputGroup = { qtyInput, incrementButton, decrementButton };
        incrementButton.addEventListener("click", () => changeQuantity(qtyInput, 1));
        decrementButton.addEventListener("click", () => changeQuantity(qtyInput, -1));
        qtyInput.addEventListener("keydown", (event) => {
          if (event.key === ARROW_UP_KEY) {
            changeQuantity(qtyInput, 1, true);
          }
          if (event.key === ARROW_DOWN_KEY) {
            changeQuantity(qtyInput, -1, true);
          }
        });
        if (qtyInput.hasAttribute("data-update-url")) {
          incrementButton.addEventListener("click", debounce(() => __async(void 0, null, function* () {
            updateQuantity(qtyInputGroup, 1);
          }), delay));
          decrementButton.addEventListener("click", debounce(() => __async(void 0, null, function* () {
            updateQuantity(qtyInputGroup, -1);
          }), delay));
          qtyInput.addEventListener("keyup", (event) => {
            const baseValue = qtyInput.getAttribute("value");
            if (qtyInput.value !== baseValue) {
              showConfirmationButtons(qtyInputGroup);
            } else {
              showSpinButtons(qtyInputGroup);
            }
            if (event.key === ENTER_KEY) {
              updateQuantity(qtyInputGroup, 1);
            }
            if (event.key === ESCAPE_KEY) {
              showSpinButtons(qtyInputGroup);
            }
          });
        }
      }
    }
  });
};
const isValidInputNum = (inputNum) => !Number.isNaN(inputNum) && Number.isInteger(inputNum);
const changeQuantity = (qtyInput, change, keyboard = false) => {
  const { mode } = qtyInput.dataset;
  if (mode !== "confirmation" || keyboard) {
    const baseValue = Number(qtyInput.getAttribute("value"));
    const currentValue = Number(qtyInput.value);
    const min = qtyInput.dataset.updateUrl === void 0 ? Number(qtyInput.getAttribute("min")) : 0;
    const newValue = Math.max(currentValue + change, min);
    qtyInput.value = String(isValidInputNum(newValue) ? newValue : baseValue);
  }
};
const updateQuantity = (qtyInputGroup, change) => __async(void 0, null, function* () {
  const { prestashop, Theme: { events } } = window;
  const { qtyInput } = qtyInputGroup;
  const { mode } = qtyInput.dataset;
  if (mode === "confirmation" && change < 0) {
    showSpinButtons(qtyInputGroup);
  } else {
    const targetValue = Number(qtyInput.value);
    const baseValue = Number(qtyInput.getAttribute("value"));
    const quantity = targetValue - baseValue;
    if (isValidInputNum(targetValue) && quantity !== 0) {
      const requestUrl = qtyInput.dataset.updateUrl;
      if (requestUrl !== void 0) {
        const targetButton = getTargetButton(qtyInputGroup, change);
        const targetButtonIcon = targetButton.querySelector("i:not(.d-none)");
        const targetButtonSpinner = targetButton.querySelector(quantityInputMap.spinner);
        toggleButtonSpinner(targetButton, targetButtonIcon, targetButtonSpinner);
        try {
          const response = yield sendUpdateCartRequest(requestUrl, quantity);
          if (response.ok) {
            const data = yield response.json();
            if (data.hasError) {
              const errors = data.errors;
              const productAlertSelector = resetAlertContainer(qtyInput);
              if (errors && productAlertSelector) {
                errors.forEach((error) => {
                  useAlert(error, { type: "danger", selector: productAlertSelector }).show();
                });
              }
            } else {
              const errors = data.errors;
              if (errors) {
                useToast(errors, { type: "warning", autohide: false }).show();
              }
              prestashop.emit(events.updateCart, {
                reason: qtyInput.dataset,
                resp: data
              });
            }
            qtyInput.value = data.quantity;
            qtyInput.setAttribute("value", data.quantity);
          } else {
            throw response;
          }
        } catch (error) {
          qtyInput.value = String(baseValue);
          const errorData = error;
          if (errorData.status !== void 0) {
            const errorMsg = `${errorData.statusText}: ${errorData.url}`;
            const productAlertSelector = resetAlertContainer(qtyInput);
            useAlert(errorMsg, { type: "danger", selector: productAlertSelector }).show();
            prestashop.emit(events.handleError, {
              eventType: "updateProductInCart",
              resp: errorData
            });
          }
        } finally {
          toggleButtonSpinner(targetButton, targetButtonIcon, targetButtonSpinner);
          showSpinButtons(qtyInputGroup);
        }
      }
    } else {
      showSpinButtons(qtyInputGroup);
    }
  }
});
const getTargetButton = (qtyInputGroup, change) => {
  const { incrementButton, decrementButton } = qtyInputGroup;
  return change > 0 ? incrementButton : decrementButton;
};
const resetAlertContainer = (qtyInput) => {
  const { alertId } = qtyInput.dataset;
  if (alertId) {
    const productAlertSelector = quantityInputMap.alert(alertId);
    const productAlertContainer = document.querySelector(productAlertSelector);
    if (productAlertContainer) {
      productAlertContainer.innerHTML = "";
    }
    return productAlertSelector;
  }
  return void 0;
};
const toggleButtonSpinner = (button, icon, spinner) => {
  button.toggleAttribute("disabled");
  icon == null ? void 0 : icon.classList.toggle("d-none");
  spinner == null ? void 0 : spinner.classList.toggle("d-none");
};
const showSpinButtons = (qtyInputGroup) => {
  const { qtyInput, incrementButton, decrementButton } = qtyInputGroup;
  const { mode } = qtyInput.dataset;
  if (mode === "confirmation") {
    toggleButtonIcon(incrementButton, decrementButton);
    qtyInput.dataset.mode = "spin";
    const baseValue = qtyInput.getAttribute("value");
    if (baseValue) {
      qtyInput.value = baseValue;
    }
  }
};
const showConfirmationButtons = (qtyInputGroup) => {
  const { qtyInput, incrementButton, decrementButton } = qtyInputGroup;
  const { mode } = qtyInput.dataset;
  if (mode !== "confirmation") {
    toggleButtonIcon(incrementButton, decrementButton);
    qtyInput.dataset.mode = "confirmation";
  }
};
const toggleButtonIcon = (incrementButton, decrementButton) => {
  const incrementButtonIcons = incrementButton.querySelectorAll("i");
  incrementButtonIcons.forEach((icon) => {
    icon.classList.toggle("d-none");
  });
  const decrementButtonIcons = decrementButton.querySelectorAll("i");
  decrementButtonIcons.forEach((icon) => {
    icon.classList.toggle("d-none");
  });
};
const sendUpdateCartRequest = (updateUrl, quantity) => __async(void 0, null, function* () {
  const formData = new FormData();
  formData.append("ajax", "1");
  formData.append("action", "update");
  formData.append("qty", String(Math.abs(quantity)));
  formData.append("op", quantity > 0 ? "up" : "down");
  const response = yield fetch(updateUrl, {
    method: "POST",
    headers: {
      Accept: "application/json, text/javascript, */*; q=0.01"
    },
    body: formData
  });
  return response;
});
document.addEventListener("DOMContentLoaded", () => {
  const { prestashop, Theme: { events, selectors } } = window;
  prestashop.on(events.updatedCart, () => {
    useQuantityInput();
    const { cart: cartMap } = selectors;
    const cartOverview = document.querySelector(cartMap.overview);
    cartOverview == null ? void 0 : cartOverview.focus();
  });
  prestashop.on(events.quickviewOpened, () => useQuantityInput(quantityInputMap.modal));
});
export default useQuantityInput;
