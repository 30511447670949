"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Modal } from "bootstrap";
import useProgressRing from "@js/components/useProgressRing";
const initCheckout = () => {
  const { prestashop } = window;
  const { Theme: { selectors, events } } = window;
  const { progressRing: ProgressRingMap, checkout: CheckoutMap } = selectors;
  const steps = document.querySelectorAll(CheckoutMap.steps.item);
  const actionButtons = document.querySelectorAll(CheckoutMap.actionsButtons);
  const { setProgress } = useProgressRing(ProgressRingMap.checkout.element, { steps: steps.length });
  const termsLink = document.querySelector(CheckoutMap.termsLink);
  const termsModalElement = document.querySelector(CheckoutMap.checkoutModal);
  const toggleStep = (content, step) => {
    const currentContent = document.querySelector(CheckoutMap.steps.current);
    currentContent == null ? void 0 : currentContent.classList.remove("step--current", "js-current-step");
    if (step) {
      const responsiveStep = document.querySelector(CheckoutMap.steps.specificStep(step.dataset.step));
      const shownResponsiveStep = document.querySelector(CheckoutMap.steps.shownResponsiveStep);
      shownResponsiveStep == null ? void 0 : shownResponsiveStep.classList.add("d-none");
      responsiveStep == null ? void 0 : responsiveStep.classList.remove("d-none");
    }
    content.classList.add("js-current-step", "step--current");
  };
  actionButtons.forEach((button) => {
    const stepContent = document.querySelector(
      CheckoutMap.steps.specificStepContent(button.dataset.step)
    );
    button.addEventListener("click", (event) => {
      event.preventDefault();
      const triggerEl = document.querySelector(
        CheckoutMap.steps.backButton(button.dataset.step)
      );
      if (stepContent && triggerEl) {
        triggerEl.click();
        toggleStep(stepContent);
      }
    });
  });
  steps.forEach((step, index) => {
    const stepContent = document.querySelector(
      CheckoutMap.steps.specificStepContent(step.dataset.step)
    );
    const stepButton = step.querySelector("button");
    if (stepContent) {
      if (stepContent.classList.contains("step--complete")) {
        step.classList.add("checkout__steps--success");
      }
      if (stepContent.classList.contains("step--current")) {
        step.classList.add("checkout__steps--current");
        stepButton == null ? void 0 : stepButton.classList.add("active");
        const responsiveStep = document.querySelector(
          CheckoutMap.steps.specificStep(step.dataset.step)
        );
        const shownResponsiveStep = document.querySelector(CheckoutMap.steps.shownResponsiveStep);
        shownResponsiveStep == null ? void 0 : shownResponsiveStep.classList.add("d-none");
        responsiveStep == null ? void 0 : responsiveStep.classList.remove("d-none");
        if (setProgress) {
          setProgress(index + 1);
        }
      } else {
        stepButton == null ? void 0 : stepButton.classList.remove("active");
      }
      if (stepContent.classList.contains("step--reachable")) {
        stepButton == null ? void 0 : stepButton.classList.add("btn-link");
        stepButton == null ? void 0 : stepButton.addEventListener("click", () => {
          if (setProgress) {
            setProgress(index + 1);
          }
          toggleStep(stepContent, step);
        });
      }
      if (stepContent.classList.contains("step--unreachable")) {
        stepButton == null ? void 0 : stepButton.setAttribute("disabled", "true");
        stepButton == null ? void 0 : stepButton.addEventListener("click", () => {
          toggleStep(stepContent, step);
        });
      }
    }
  });
  termsLink == null ? void 0 : termsLink.addEventListener("click", (event) => {
    event.preventDefault();
    if (termsModalElement) {
      const termsModal = new Modal(termsModalElement);
      const linkElement = event.target;
      let url = linkElement.getAttribute("href");
      if (url) {
        url += "?content_only=1";
        (() => __async(void 0, null, function* () {
          try {
            const response = yield fetch(url);
            const content = yield response.text();
            const contentElement = document.createElement("div");
            contentElement.innerHTML = content;
            const modalBody = termsModalElement.querySelector(selectors.modalBody);
            const sanitizedContent = contentElement.querySelector(selectors.pageCms);
            if (sanitizedContent && modalBody) {
              modalBody.innerHTML = sanitizedContent.innerHTML;
              termsModal.show();
            }
          } catch (e) {
            prestashop.emit(events.handleError, { eventType: "clickOnTermsLink", error: e });
          }
        }))();
      }
    }
  });
  prestashop.on(events.updatedDeliveryForm, (params) => {
    var _a;
    if (typeof params.deliveryOption === "undefined" || Object.keys(params.deliveryOption).length === 0) {
      return;
    }
    const extraContentElements = document.querySelectorAll(CheckoutMap.carrierExtraContentWrapper);
    extraContentElements.forEach((content) => {
      content.style.maxHeight = "0";
    });
    const extraContentElementToShow = (_a = params.deliveryOption[0]) == null ? void 0 : _a.querySelector(CheckoutMap.carrierExtraContentWrapper);
    if (extraContentElementToShow != null) {
      const content = extraContentElementToShow.querySelector(CheckoutMap.carrierExtraContent);
      if (content != null) {
        extraContentElementToShow.style.maxHeight = `${content.clientHeight}px`;
      }
    }
  });
  const setMaxHeightToActiveCarrierExtraContent = () => {
  };
  if (document.readyState === "complete" || document.readyState === "interactive") {
    setTimeout(() => {
      setMaxHeightToActiveCarrierExtraContent();
    }, 1);
  } else {
    document.addEventListener("DOMContentLoaded", () => {
      setMaxHeightToActiveCarrierExtraContent();
    });
  }
};
export default initCheckout;
