"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import swapElements from "@helpers/swapElements";
import { facetedsearch } from "@constants/selectors-map";
import { initSliders } from "@js/modules/facetedsearch";
import { Offcanvas } from "bootstrap";
const { prestashop } = window;
if (prestashop) {
  prestashop.responsive = prestashop.responsive || {};
  prestashop.responsive.current_width = window.innerWidth;
  prestashop.responsive.min_width = 768;
  prestashop.responsive.mobile = prestashop.responsive.current_width < prestashop.responsive.min_width;
}
export function toggleMobileStyles() {
  var _a;
  const { prestashop: prestashop2, Theme: { events } } = window;
  if (prestashop2.responsive.mobile) {
    Array.prototype.forEach.call(document.querySelectorAll("*[id^='_desktop_']"), (el) => {
      const source = document.querySelector(`#${el.id}`);
      const target = document.querySelector(`#${el.id.replace("_desktop_", "_mobile_")}`);
      if (target && source) {
        swapElements(source, target);
      }
    });
    initSliders();
  } else {
    Array.prototype.forEach.call(document.querySelectorAll("*[id^='_mobile_']"), (el) => __async(this, null, function* () {
      const source = document.querySelector(`#${el.id}`);
      const target = document.querySelector(`#${el.id.replace("_mobile_", "_desktop_")}`);
      if (target && source) {
        swapElements(source, target);
      }
    }));
    const offCanvasFacetedElement = document.querySelector(facetedsearch.offCanvasFaceted);
    if (offCanvasFacetedElement != null) {
      (_a = Offcanvas.getInstance(offCanvasFacetedElement)) == null ? void 0 : _a.hide();
    }
    initSliders();
  }
  prestashop2.emit(events.responsiveUpdate, {
    mobile: prestashop2.responsive.mobile
  });
}
export default function initResponsiveToggler() {
  const { prestashop: prestashop2 } = window;
  prestashop2.responsive = prestashop2.responsive || {};
  prestashop2.responsive.current_width = window.innerWidth;
  prestashop2.responsive.min_width = 768;
  prestashop2.responsive.mobile = prestashop2.responsive.current_width < prestashop2.responsive.min_width;
  window.addEventListener("resize", () => {
    const currentWidth = prestashop2.responsive.current_width;
    const minWidth = prestashop2.responsive.min_width;
    const screenWidth = window.innerWidth;
    const toggle = currentWidth >= minWidth && screenWidth < minWidth || currentWidth < minWidth && screenWidth >= minWidth;
    prestashop2.responsive.current_width = screenWidth;
    prestashop2.responsive.mobile = prestashop2.responsive.current_width < prestashop2.responsive.min_width;
    if (toggle) {
      toggleMobileStyles();
    }
  });
}
document.addEventListener("DOMContentLoaded", () => {
  if (prestashop.responsive.mobile) {
    toggleMobileStyles();
  }
});
