"use strict";
import getQueryParameters from "./urlparser";
export default function(values, slider) {
  const { prestashop, Theme: { events } } = window;
  let queryParams = [];
  const nextEncodedFacetsURL = slider.target.dataset.sliderEncodedUrl;
  const urlsSplitted = nextEncodedFacetsURL.split("?");
  if (urlsSplitted !== void 0 && urlsSplitted.length > 1) {
    queryParams = getQueryParameters(urlsSplitted[1]);
  }
  let found = false;
  queryParams.forEach((query) => {
    if (query.name === "q") {
      found = true;
    }
  });
  if (!found) {
    queryParams.push({ name: "q", value: "" });
  }
  queryParams.forEach((query) => {
    if (query.name === "q") {
      query.value += [
        query.value.length > 0 ? "/" : "",
        slider.target.dataset.sliderLabel,
        "-",
        slider.target.dataset.sliderUnit,
        "-",
        values[0],
        "-",
        values[1]
      ].join("");
    }
  });
  const newUrl = [
    urlsSplitted[0],
    "?",
    $.param(queryParams)
  ].join("");
  prestashop.emit(events.updateFacets, newUrl);
}
