"use strict";
import { Collapse } from "bootstrap";
import { isHTMLElement } from "@helpers/typeguards";
import handleCartAction from "../components/UseHandleCartAction";
export default () => {
  const { Theme } = window;
  const voucherCodes = document.querySelectorAll(Theme.selectors.cart.discountCode);
  voucherCodes.forEach((voucher) => {
    voucher.addEventListener("click", (event) => {
      event.stopPropagation();
      if (isHTMLElement(event.currentTarget)) {
        const code = event.currentTarget;
        const discountInput = document.querySelector(Theme.selectors.cart.discountName);
        const promoCode = document.querySelector(Theme.selectors.cart.promoCode);
        if (promoCode && discountInput) {
          const formCollapser = new Collapse(promoCode);
          discountInput.value = code.innerText;
          formCollapser.show();
        }
      }
      return false;
    });
  });
  const cartContainer = document.querySelector(Theme.selectors.cart.container);
  if (cartContainer) {
    cartContainer.addEventListener("click", (event) => {
      const eventTarget = event.target;
      if (eventTarget.dataset.linkAction === Theme.selectors.cart.deleteLinkAction) {
        handleCartAction(event);
      }
    });
  }
};
