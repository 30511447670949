"use strict";
import useToast from "./components/useToast";
const initErrorHandler = () => {
  const { Theme: { events } } = window;
  const { prestashop } = window;
  prestashop.on(events.handleError, ({ resp }) => {
    if (resp.errors) {
      resp.errors.forEach((error) => {
        useToast(error, { type: "danger" }).show();
      });
    }
  });
};
export default initErrorHandler;
