"use strict";
export default {
  quickviewOpened: "quickviewOpened",
  clickQuickview: "clickQuickview",
  handleError: "handleError",
  responsiveUpdate: "responsiveUpdate",
  updateCart: "updateCart",
  updatedCart: "updatedCart",
  updateProductList: "updateProductList",
  updateProduct: "updateProduct",
  updatedProduct: "updatedProduct",
  updateFacets: "updateFacets",
  updatedDeliveryForm: "updatedDeliveryForm"
};
