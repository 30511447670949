"use strict";
const handleCartAction = (event) => {
  event.stopPropagation();
  event.preventDefault();
  const target = event.target;
  sendCartRefreshRequest(target);
};
const sendCartRefreshRequest = (target) => {
  const { prestashop, Theme: { events } } = window;
  const { dataset } = target;
  const targetUrl = target.getAttribute("href");
  if (targetUrl === null) {
    return;
  }
  const formData = new FormData();
  formData.append("ajax", "1");
  formData.append("action", "update");
  fetch(targetUrl, {
    method: "POST",
    body: formData
  }).then((resp) => {
    prestashop.emit(events.updateCart, {
      reason: dataset,
      resp
    });
  }).catch((err) => {
    const errorData = err;
    prestashop.emit(events.handleError, {
      eventType: "updateProductInCart",
      errorData
    });
  });
};
export default handleCartAction;
