"use strict";
import SelectorsMap from "./constants/selectors-map";
export default () => {
  const { prestashop, Theme: { events } } = window;
  const initProductSlide = () => {
    var _a;
    (_a = document.querySelector(SelectorsMap.product.carousel)) == null ? void 0 : _a.addEventListener("slide.bs.carousel", onProductSlide);
  };
  function onProductSlide(event) {
    var _a;
    document.querySelectorAll(SelectorsMap.product.thumbnail).forEach((e) => e.classList.remove("active"));
    (_a = document.querySelector(SelectorsMap.product.activeThumbail(event.to))) == null ? void 0 : _a.classList.add("active");
  }
  initProductSlide();
  prestashop.on(events.updatedProduct, initProductSlide);
  prestashop.on(events.quickviewOpened, initProductSlide);
};
