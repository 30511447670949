"use strict";
const initLanguageSelector = () => {
  const { Theme } = window;
  const { languageSelector: LanguageSelectorMap } = Theme.selectors;
  const languageSelector = document.querySelector(LanguageSelectorMap.languageSelector);
  languageSelector == null ? void 0 : languageSelector.addEventListener("change", (event) => {
    const option = event.target;
    window.location.href = option.value;
  });
};
export default initLanguageSelector;
